// import styles
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "katex/dist/katex.min.css"
import "./src/styles/main.css"
import "./src/styles/fonts.css"
import "prismjs/themes/prism.css"

// wrap elements in state context
import * as React from "react"
import Store from "./src/state/store"

export const wrapRootElement = ({ element }) => {
    return <Store>{ element }</Store>
}
