import * as actions from "../constants/actions"
import { MENU_TEXT } from "../constants/menu";

const Reducer = (state, action) => {
    switch(action.type) {
        case actions.SET_TEXT:
            return {
                ...state,
                text: action.payload
            }
        case actions.CLEAR_TEXT:
            return {
                ...state,
                text: ""
            }
        case actions.SET_FEATURES:
            return {
                ...state,
                features: action.payload
            }
        case actions.CLEAR_FEATURES:
            return {
                ...state,
                features: null
            }
        case actions.SET_WORD_FREQ_LIST:
            return {
                ...state,
                word_freq_list: action.payload
            }
        case actions.CLEAR_WORD_FREQ_LIST:
            return {
                ...state,
                word_freq_list: []
            }
        case actions.SET_NE_FREQ_LIST:
            return {
                ...state,
                ne_freq_list: action.payload
            }
        case actions.CLEAR_NE_FREQ_LIST:
            return {
                ...state,
                ne_freq_list: []
            }
        case actions.SET_TOKENS:
            return {
                ...state,
                tokens: action.payload
            }
        case actions.CLEAR_TOKENS:
            return {
                ...state,
                tokens: []
            }
        case actions.SET_SENTENCES:
            return {
                ...state,
                sentences: action.payload
            }
        case actions.CLEAR_SENTENCES:
            return {
                ...state,
                sentences: []
            }
        case actions.SET_INTEREST_WORD:
            return {
                ...state,
                interest_word: action.payload
            }
        case actions.CLEAR_INTEREST_WORD:
            return {
                ...state,
                interest_word: ""
            }
        case actions.SET_MAIN_DISPLAY:
            return {
                ...state,
                main_display: action.payload
            }
        case actions.SET_LOADING_TRUE:
            return {
                ...state,
                loading: true
            }
        case actions.SET_LOADING_FALSE:
            return {
                ...state,
                loading: false
            }
        case actions.SET_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case actions.CLEAR_STATE:
            return {
                ...state,
                main_display: MENU_TEXT,
                text: "",
                tokens: [],
                sentences: [],
                features: null,
                interest_word: "",
                word_freq_list: [],
                ne_freq_list: []
            }
        default:
            return state
    }
}

export default Reducer
