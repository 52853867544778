import React, { createContext, useReducer } from "react"
import Reducer from "./reducer"
import { MENU_TEXT } from "../constants/menu"

const initialState = {
    text: "",
    tokens: [],
    sentences: [],
    main_display: MENU_TEXT,
    features: null,
    word_freq_list: [],
    ne_freq_list: [],
    interest_word: "",
    loading: false,
    error: null
}

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState)

    return (
        <Context.Provider value={ [state, dispatch] }>
            {children}
        </Context.Provider>
    )
}

export const Context = createContext(initialState)

export default Store
