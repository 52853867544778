export const SET_TEXT = "SET_TEXT"
export const CLEAR_TEXT = "CLEAR_TEXT"
export const SET_TOKENS = "SET_TOKENS"
export const CLEAR_TOKENS = "CLEAR_TOKENS"
export const SET_SENTENCES = "SET_SENTENCES"
export const CLEAR_SENTENCES = "CLEAR_SENTENCES"
export const SET_FEATURES = "SET_FEATURES"
export const CLEAR_FEATURES = "CLEAR_FEATURES"
export const SET_WORD_FREQ_LIST = "SET_WORD_FREQ_LIST"
export const CLEAR_WORD_FREQ_LIST = "CLEAR_WORD_FREQ_LIST"
export const SET_NE_FREQ_LIST = "SET_NE_FREQ_LIST"
export const CLEAR_NE_FREQ_LIST = "CLEAR_NE_FREQ_LIST"
export const SET_INTEREST_WORD = "SET_INTEREST_WORD"
export const CLEAR_INTEREST_WORD = "CLEAR_INTEREST_WORD"
export const SET_LOADING_TRUE = "SET_LOADING_TRUE"
export const SET_LOADING_FALSE = "SET_LOADING_FALSE"
export const SET_ERROR = "SET_ERROR"
export const CLEAR_STATE = "CLEAR_STATE"
export const SET_MAIN_DISPLAY = "SET_MAIN_DISPLAY"
